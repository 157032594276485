/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from 'gatsby';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled/macro';
import { Flex, Box, Text, Heading } from 'rebass';

import substrDescription from '../../utils/substrDescription';
import strings from './strings.json';

const Image = styled(GatsbyImage)``;

const StyledBox = styled(Box)`
  & ${Image} {
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }

  &:hover ${Image} {
    transform: scale(1.05);
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
`;

const MainPageProductBlock = (props) => {
  const {
    title,
    description,
    featuredImage,
    fields: { shopifyThemePath },
  } = props.product;
  const { availableForPreorder } = strings;

  const { gatsbyImageProps, enablePreorder } = props;

  const { textColor = 'primary', textBgColor = 'white' } = props;

  return (
    <StyledBox sx={{ position: 'relative' }}>
      <GatsbyLink
        to={shopifyThemePath}
        sx={{
          color: textColor,
          textDecoration: 'none',
          ':hover,:focus,.active': {
            color: textColor,
            textDecoration: 'none',
          },
        }}
      >
        <Box sx={{ overflow: 'hidden' }}>
          {featuredImage ? (
            <Image
              image={featuredImage.localFile.childImageSharp.gatsbyImageData}
              alt={title}
              // eslint-disable-next-line
              {...gatsbyImageProps}
            />
          ) : (
            <Box pt="60%" />
          )}
        </Box>

        <Box
          sx={{
            position: ['absolute'],
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column'],
              justifyContent: 'flex-end',
              alignItems: ['center'],
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              width={['auto']}
              sx={{
                ml: [1],
                my: ['auto'],
                backgroundColor: [textBgColor],
                opacity: [0.8],
                px: ['3%'],
                py: ['2%'],
              }}
            >
              <Heading
                as="h2"
                fontSize={[15, 20]}
                textAlign={['center']}
              >
                { enablePreorder ? availableForPreorder :  substrDescription(title, 30) }
              </Heading>
            </Box>
          </Flex>
        </Box>
      </GatsbyLink>
    </StyledBox>
  );
};

export default MainPageProductBlock;
