/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const Image = styled(GatsbyImage)``;

const StyledBox = styled(Box)`
  & ${Image} {
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }

  &:hover ${Image} {
    transform: scale(1.05);
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
`;

const MainPageImageBlock = (props) => {
  const {
    name,
    image,
    gatsbyImageProps,
  } = props;

  return (
    
      <StyledBox sx={{ position: 'relative' }}>
        <Box sx={{ overflow: 'hidden' }}>
          <Image
            image={getImage(image)}
            alt={name}
            // eslint-disable-next-line
            {...gatsbyImageProps}
          />
        </Box>
      </StyledBox>
  );
};

export default MainPageImageBlock;
