/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled/macro';
import { Box, Heading } from 'rebass';

const StyledBox = styled(Box)`
  & > h1 {
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }

  &:hover > h1 {
    transform: scale(1.05);
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
`;

const MainPageTextBlock = (props) => {
  const {
    name, 
    textColor = 'primary',
    textBgColor = 'black',
  } = props;

  return (
    <StyledBox sx={{ 
      position: 'relative',
      bg: textBgColor,
      color: textColor,
      backgroundSize: 'cover',
      borderRadius: '8',
      width: '100%',
      height: '100%',
      pt: ['20%', '30%', '35%'],
      pb: ['5%', '5%', '20%'],
      pl: ['5%', '5%', '10%'],
      pr: ['10%', '20%', '35%'],
    }}>  
      <Heading
        as="h1"
        fontSize={[30, 50, 70]}
        textAlign='left'                
      >
        {name}
      </Heading>
    </StyledBox>
  );
};

export default MainPageTextBlock;
