/* eslint react/jsx-props-no-spreading: "off" */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import MainPage from './MainPage';
import Layout from '../../components/Layout';
import strings from './strings.json';

const { pageTitleTemplate } = strings;

const Main = (props) => {
  const {
    storeName,
  } = props.data.store.siteMetadata.gatsbyStorefrontConfig;

  return (
    <Layout>
      <Helmet title={storeName} titleTemplate={pageTitleTemplate} defer={false} />
      <MainPage {...props} />
    </Layout>
  );
};

export default Main;

export const mainPageQuery = graphql`query MainPageQueryShadow($handles: [String]) {
  catalogs: allShopifyCollection(filter: {handle: {in: $handles}}) {
    nodes {
      handle
      title
      description
      fields {
        shopifyThemePath
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1300
              height: 800
              backgroundColor: "white"
              transformOptions: {cropFocus: CENTER, fit: COVER}
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
  products: allShopifyProduct(filter: {handle: {in: $handles}}) {
    nodes {
      title
      description
      handle
      fields {
        shopifyThemePath        
      }
      featuredImage {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "white"
              transformOptions: {cropFocus: CENTER, fit: COVER}
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
  store: site {
    siteMetadata {
      gatsbyStorefrontConfig {
        storeName
        storeDescription
        enablePreorder
        gatsbyImageProps {
          loading
          fadeIn
          durationFadeIn
        }
      }
    }
  }
  mainpageimages: allFile(filter: {relativePath: {in: $handles}}) {
    nodes {
      childImageSharp {
        gatsbyImageData(
          width: 1300
          height: 800
          backgroundColor: "white"
          transformOptions: {cropFocus: CENTER, fit: COVER}
          layout: CONSTRAINED
        )
      }
      handle: relativePath
    }
  }
}
`;
