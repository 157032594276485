import React from 'react';
import { Flex, Box } from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import MainPageCarousel from './MainPageCarousel';
import MainPageCatalogBlock from './MainPageCatalogBlock';
import MainPageProductBlock from './MainPageProductBlock';
import MainPagePopupBlock from './MainPagePopupBlock';
import MainPageImageBlock from './MainPageImageBlock';
import strings from './strings.json';

const { pageTitleTemplate } = strings;

const MainPage = (props) => {
  const dataQuery = useStaticQuery(graphql`
    query MainPageStaticQueryShadow {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            mainPage {
              handle
              type
              name
              textBgColor
              textColor
              children {
                handle
                type
                name
                textBgColor
                textColor
              }
            }
          }
        }
      }
    }
  `);

  const { mainPage } = dataQuery.site.siteMetadata.gatsbyStorefrontConfig;

  const { data, location } = props;

  const {
    storeName,
    storeDescription,
    enablePreorder,
    gatsbyImageProps
  } = data.store.siteMetadata.gatsbyStorefrontConfig;

  return (
    <>
      <GatsbySeo
        title={storeName}
        titleTemplate={pageTitleTemplate}
        description={storeDescription}
        openGraph={{
          url: location.href,
          title: storeName,
          description: storeDescription,
          images:[{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Flex flexWrap="wrap" px={2} pt={3} mx="auto" style={{ maxWidth: 1300 }}>
        {mainPage.map((block, index) => {
          if (block.type === 'carousel') {
            return (
              <Box width={1} p={1} key={index}>
                <MainPageCarousel
                  carousel={block}
                  data={data}
                  gatsbyImageProps={gatsbyImageProps}
                  enablePreorder={enablePreorder}
                />
              </Box>
            );
          } else if (block.type === 'popup') {
            return (
              <Box width={[1, 1 / 2]} p={1} key={index}>
                <MainPagePopupBlock
                  gatsbyImageProps={gatsbyImageProps}
                  name={block.name}
                  textColor={block.textColor}
                  textBgColor={block.textBgColor}
                />
              </Box>
            );
          } else if (block.type === 'header') {
            return '';
          } else if (
            block.type === 'catalog' &&
            data.catalogs.nodes.filter(
              (catalog) => catalog.handle === block.handle
            )[0]
          ) {
            return (
              <Box width={[1, 1 / 2]} p={1} key={index}>
                <MainPageCatalogBlock
                  catalog={
                    data.catalogs.nodes.filter(
                      (catalog) => catalog.handle === block.handle
                    )[0]
                  }
                  name={block.name}
                  textColor={block.textColor}
                  textBgColor={block.textBgColor}
                  gatsbyImageProps={gatsbyImageProps}
                />
              </Box>
            );
          } else if (
            block.type === 'product' &&
            data.products.nodes.filter(
              (product) => product.handle === block.handle
            )[0]
          ) {
            return (
              <Box width={[1, 1 / 2, 1 / 3]} p={1} key={index}>
                <MainPageProductBlock
                  product={
                    data.products.nodes.filter(
                      (product) => product.handle === block.handle
                    )[0]
                  }
                  textColor={block.textColor}
                  textBgColor={block.textBgColor}
                  gatsbyImageProps={gatsbyImageProps}
                  enablePreorder={enablePreorder}
                />
              </Box>
            );
          } else if (block.type === 'image' &&
            data.mainpageimages.nodes.filter(
              (image) => image.handle === block.handle
            )[0]) {
            return (
              <Box width={[1, 1 / 2]} p={1} key={index}>
                <MainPageImageBlock
                  name={block.name}
                  image={
                    data.mainpageimages.nodes.filter(
                      (image) => image.handle === block.handle
                    )[0]
                  }
                  gatsbyImageProps={gatsbyImageProps}
                />
              </Box>
            );
          } else {
            return '';
          }
        })}
      </Flex>
    </>
  );
};

export default MainPage;
