/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from 'gatsby';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled/macro';
import { Flex, Box, Text, Heading } from 'rebass';

import substrDescription from '../../utils/substrDescription';

const Image = styled(GatsbyImage)``;

const StyledBox = styled(Box)`
  & ${Image} {
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }

  &:hover ${Image} {
    transform: scale(1.05);
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
`;

const MainPageCatalogBlock = (props) => {
  const {
    title,
    description,
    image,
    fields: { shopifyThemePath },
  } = props.catalog;
  const {
    textColor = 'primary',
    textBgColor = 'white',
    gatsbyImageProps,
    name,
  } = props;

  return (
    <GatsbyLink
              to={shopifyThemePath}
              sx={{
                color: textColor,
                textDecoration: 'none',
                ':hover,:focus,.active': {
                  color: textColor,
                  textDecoration: 'none',
                },
              }}
            >
    <StyledBox sx={{ position: 'relative' }}>
      <Box sx={{ overflow: 'hidden' }}>
        {image ? (
          <Image
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={title}
            // eslint-disable-next-line
            {...gatsbyImageProps}
          />
        ) : (
          <Box pt="60%" />
        )}
      </Box>

      <Box
        sx={{
          position: ['absolute'],
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column'],
            justifyContent: 'flex-start',
            alignItems: ['flex-start'],
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            width={['auto']}
            sx={{
              ml: [1],
              mt: [3],
              backgroundColor: [textBgColor],
              opacity: [0.8],
              px: ['3%'],
              py: ['2%'],
            }}
          >
            {' '}
              <Heading
                as="h2"
                fontSize={[30, 36, 42]}
                textAlign={['left']}
              >
                {name}
              </Heading>

          </Box>
        </Flex>
      </Box>
    </StyledBox>
    </GatsbyLink>
  );
};

export default MainPageCatalogBlock;
