/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';
import mapMarker from '@iconify-icons/oi/map-marker';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled/macro';
import { Box, Heading, Text } from 'rebass';
import { StaticImage } from "gatsby-plugin-image";
import strings from './strings.json';

const Link = styled.a``
const AddressText = styled(Text)``
const StyledBox = styled(Box)`
  & > ${AddressText} {
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }

  &:hover > ${AddressText} {
    transform: scale(1.05);
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
`;

const { visitOurStore, storeAddress, storeAddressGoogleMapsLink } = strings;

const MainPagePopupBlock = (props) => {
  const {
    name,
    textColor = 'primary',
    textBgColor = 'black',
  } = props;

  const disabledLink = storeAddressGoogleMapsLink === "#" ? { 'pointer-events': 'none'} : null

  return (
    <Link
      href={storeAddressGoogleMapsLink}
      target="_blank"
      rel="noreferrer"
      sx={{
        color: textColor,
        textDecoration: 'none',
        ':hover,:focus,.active': {
          color: textColor,
          textDecoration: 'none',
        },
        ...disabledLink,
      }}
    >
      <StyledBox sx={{
        position: 'relative',
        bg: textBgColor,
        color: textColor,
        justifyContent: 'center',
        textAlign: 'center',
        backgroundSize: 'cover',
        borderRadius: '8',
        border: '1px solid black',
        width: '100%',
        height: '100%',
      }}>
        <StaticImage
          src="../../images/popup3.png"
          alt="POP-UP"
          objectFit="contain"
          layout="constrained"
        />
        <Heading
          as="h1"
          fontSize={[20, 20, 35 ]}
        >
          {visitOurStore}
        </Heading>

        <AddressText
          fontSize={[2, 3, 4]}
          mt={[2]}
        >
          <address>
            <Icon icon={mapMarker} />
            {storeAddress}
          </address>
        </AddressText>

      </StyledBox>
    </Link>
  );
};

export default MainPagePopupBlock;
